import React, { useState, useEffect, useContext } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loading from '@artemis/components/Loading';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import AccountControls from '@artemis/containers/AccountControls';
import LocationSearchField from '@artemis/containers/LocationSearchField';
import AuthenticationContext from '@artemis/integrations/auth/AuthenticationContext';
import {
  useFormatMessage,
  useImageUrlAlt,
  FormattedMessage,
} from '@artemis/integrations/contentful/utils';
import { setGeolocationCookie, getCity } from './utils';

const Heading = styled.h1`
  color: ${props => props.theme.palette.common.white};
  ${props => props.theme.typography.h1};
`;

const SignInText = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.palette.common.white};
`;

const SignInAction = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-decoration-line: underline;
  position: relative;
  color: ${props => props.theme.palette.common.white};
`;

const ImageContainer = styled.div`
  max-width: 126px;
  max-height: 32px;
  position: absolute;
  top: 16px;
  left: 16px;
`;

const BackgroundImage = styled(Image)`
  filter: brightness(50%);
  z-index: ${props => props.theme.zIndices.hide};
`;

const AccountControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  min-width: 80px;
  background-color: transparent;
  position: absolute;
  top: 14px;
  right: 22px;
`;

const SearchContainer = styled.div`
  position: relative;
  margin: 24px 0px;
  width: 85%;
  ${({ theme }) => theme.isTablet`
    width: 100%;
    max-width: 400px;
  `}

  > input {
    ${props => props.theme.typography.body};
    line-height: 16px;
    background: ${props => props.theme.rtColors.black100};
    border: none;
    border-radius: 8px;
    padding: 16px 50px;
    width: 100%;
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  text-align: center;
  margin: auto;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const HeroSection = ({ setLocation, headingText }) => {
  const { authenticated, setAuthModal } = useContext(AuthenticationContext);
  const [locationQuery, setLocationQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [latLon, setLatLon] = useState({});
  const [city, setCity] = useState('');
  const locationPlaceholder = useFormatMessage({
    entry: 'landing.locationPlaceholder',
  });
  const heroImage = useImageUrlAlt('landing.hero.img');

  useEffect(() => {
    if (!isLoading && latLon.lat && latLon.lon) {
      setLocation({
        lat: latLon.lat,
        lon: latLon.lon,
        city,
        locationQuery,
      });
    }
  }, [isLoading, latLon.lat, latLon.lon]);

  const openAuthModal = () =>
    setAuthModal({
      showLoginModal: true,
    });

  const setGeolocation = geolocation => {
    setLocationQuery(geolocation.formattedAddress);
    setGeolocationCookie(geolocation);
  };
  return (
    <Container>
      <BackgroundImage
        loader={({ src, width, quality }) =>
          `https:${src}&w=${width}&q=${quality || 75}`
        }
        src={heroImage.url}
        alt=""
        layout="fill"
        objectFit="cover"
        quality={100}
        priority
        data-testid="hero-image"
      />
      <ImageContainer>
        <ResponsiveImage id="promo.whiteRitualLogo.img" />
      </ImageContainer>
      <AccountControlsContainer authenticated={authenticated}>
        <AccountControls signInTheme="secondary" />
      </AccountControlsContainer>
      <Heading>
        <FormattedMessage entry={headingText} />
      </Heading>
      <SearchContainer>
        <Loading isLoading={isLoading} />
        <LocationSearchField
          placeholder={locationPlaceholder}
          setLatLon={setLatLon}
          setIsLoading={setIsLoading}
          setSearchText={setLocationQuery}
          setAddressComponents={addressComponents =>
            setCity(getCity(addressComponents))
          }
          setGeolocation={setGeolocation}
          searchText={locationQuery || ''}
        />
      </SearchContainer>
      {!authenticated && (
        <SignInText>
          <FormattedMessage
            entry="landing.signInForAddresses"
            values={{
              button: text => (
                <SignInAction onClick={openAuthModal}>{text}</SignInAction>
              ),
            }}
          />
        </SignInText>
      )}
    </Container>
  );
};

HeroSection.propTypes = {
  setLocation: PropTypes.func,
  headingText: PropTypes.string,
};
