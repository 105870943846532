import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Banner } from '@artemis/components/Banner';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { logMenuViewIncentivesClick } from '@artemis/containers/Incentives/analytics';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import { toggleListModal } from '@artemis/store/incentives/slice';
import { getMerchantId } from '@artemis/store/merchant/selectors';

const getTitle = incentiveGroups =>
  incentiveGroups.filter(group => group?.incentives.length)[0]?.incentives[0]
    ?.title;

const CreditIncentiveBanner = ({
  incentiveCount,
  creditAmount,
  incentiveGroups,
  creditMicro,
}) => {
  const dispatch = useDispatch();
  const merchantId = useSelector(getMerchantId);
  const incentivesAvailable = useFormatMessage({
    entry: 'menu.incentives.available',
    values: {
      value: incentiveCount,
    },
  });
  const creditIncentivesAvailable = useFormatMessage({
    entry: 'menu.incentives.creditsAndIncentivesAvailable',
    values: {
      credits: creditAmount,
      incentiveCount,
    },
  });

  let bannerText = '';
  if (incentiveCount === 1 && creditMicro === 0) {
    bannerText = getTitle(incentiveGroups);
  } else if (creditMicro === 0) {
    bannerText = incentivesAvailable;
  } else {
    bannerText = creditIncentivesAvailable;
  }

  const onClick = () => {
    logMenuViewIncentivesClick({ merchantId });
    dispatch(toggleListModal({ isOpen: true }));
  };

  return (
    <Banner
      isPrimary
      cursorOnHover
      data-testid="credit-incentive-banner"
      rightIcon={<ResponsiveImage id="icons.chevronRightWhite.img" />}
      onClick={onClick}
    >
      {bannerText}
    </Banner>
  );
};

CreditIncentiveBanner.propTypes = {
  incentiveCount: PropTypes.number,
  creditAmount: PropTypes.string,
  creditMicro: PropTypes.number,
  incentiveGroups: PropTypes.array,
};

export default CreditIncentiveBanner;
