import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import MerchantStateOverlay from '@artemis/components/MerchantTileList/MerchantStateOverlay';
import PromoChip from '@artemis/components/PromoChip';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { getOrderSourceOrigin } from '@artemis/store/order/selectors';
import {
  FILTER_CATERING_PARAM,
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
} from '@artemis/utils/query/constants';
import {
  getMinutesUntilTime,
  FORMAT,
  formatDate,
  parseUTC,
} from '@artemis/utils/time-format';
import { CATEGORY_SEPARATOR, CLOSING_SOON_MINUTES, PADDING } from './constants';
import Chip from './Chip';
import { PromotionShape } from '../propTypes';

const LinkContainer = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden; // Enforce children to follow border-radius
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.shadow6};
  color: ${({ theme }) => theme.palette.common.black};
  cursor: pointer;
  text-decoration: none;
`;

const ImageContainer = styled.div`
  height: 160px;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ClosingSoonChip = styled(Chip)`
  background-color: ${({ theme }) => theme.rtColors.black900};
  position: absolute;
  left: ${PADDING};
  bottom: ${PADDING};
`;

const StyledPromoChip = styled(PromoChip)`
  position: absolute;
  right: ${PADDING};
  bottom: ${PADDING};
`;

const Details = styled.div`
  flex: 1;
  padding: ${PADDING};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Name = styled.h3`
  ${({ theme }) => theme.typography.lineClamp(2)};
  ${({ theme }) => theme.typography.h6};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

const LoyaltyIcon = styled(ResponsiveImage).attrs({ id: 'icons.loyalty.img' })`
  align-self: start;
  display: flex;
  flex: 0 0 32px;
`;

const Categories = styled.p`
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.rtColors.black700};
  margin-bottom: 2px;
`;

const Address = styled.p`
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.rtColors.black800};
  margin-bottom: 8px;
`;

const Distance = styled.p`
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.rtColors.black800};
  margin-top: auto;
`;

const getMenuUrl = ({
  queryParams,
  pathParams,
  menuPath,
  orderSourceOrigin,
}) => {
  const params = new URLSearchParams(queryParams);
  if (pathParams.city) {
    params.set(FILTER_CITY_PARAM, pathParams.city);
  }
  if (pathParams.cuisine) {
    params.set(FILTER_CUISINE_PARAM, pathParams.cuisine);
  }
  if (pathParams.catering) {
    params.set(FILTER_CATERING_PARAM, pathParams.catering);
  }
  return `${orderSourceOrigin}/order${menuPath}?${params.toString()}`;
};

const getDateEntry = date => {
  if (!date) {
    return null;
  }
  const parsedDate = parseUTC(date);
  const targetDay = parsedDate.startOf('day');
  const currentDay = dayjs().startOf('day');
  const daysAhead = targetDay.diff(currentDay, 'day');
  if (daysAhead === 0) {
    return {
      entry: 'landing.opensToday',
      time: formatDate(parsedDate, FORMAT.TIME),
    };
  }
  if (daysAhead > 0) {
    return {
      entry: 'landing.opensOther',
      time: formatDate(parsedDate, FORMAT.DAY_OF_WEEK_AND_TIME_WITHOUT_COMMA),
    };
  }
  return null;
};

const MerchantCard = ({
  className,
  name,
  address: { streetAddress },
  imageUrl,
  menuPath,
  isAvailableNow,
  isOpenNow,
  nextCloseTimestamp,
  loyaltyPlusProgram,
  promotions,
  topMerchantsOnly = false,
  tags,
  nextOpenHours = [],
  formattedDistanceString,
  showDistance,
  handleClick = () => {},
  hasScheduledOrdering,
  pathParams,
  queryParams,
}) => {
  const orderSourceOrigin = useSelector(getOrderSourceOrigin);

  const menuUrl = getMenuUrl({
    queryParams,
    pathParams,
    menuPath,
    orderSourceOrigin,
  });
  const defaultImageUrl = useFormatMessage({
    entry: 'brand.location.defaultImageUrl',
  });
  const isClosingSoon = useMemo(() => {
    if (!nextCloseTimestamp) return false;
    const minutesUntilClosing = getMinutesUntilTime(nextCloseTimestamp);
    return (
      minutesUntilClosing > 0 && minutesUntilClosing <= CLOSING_SOON_MINUTES
    );
  }, [nextCloseTimestamp]);

  const entryFormatDate = getDateEntry(nextOpenHours[0]?.from);
  const showScheduledOrdering =
    !isOpenNow && hasScheduledOrdering && !!entryFormatDate;
  const merchantNextOpen = useFormatMessage({
    entry: entryFormatDate?.entry,
    values: { time: entryFormatDate?.time },
  });

  const getLabel = () => {
    if (isOpenNow) {
      return isAvailableNow ? '' : 'merchantState.unavailable';
    }
    if (entryFormatDate) {
      return merchantNextOpen;
    }
    return 'merchantState.unavailable';
  };

  return (
    <LinkContainer className={className} onClick={handleClick} href={menuUrl}>
      <ImageContainer>
        <Image src={imageUrl || defaultImageUrl} alt={name} />
        <MerchantStateOverlay
          label={getLabel()}
          showScheduledOrdering={showScheduledOrdering}
        />
        {isOpenNow && isClosingSoon && (
          <ClosingSoonChip data-testid="merchant-card-closing-chip">
            <FormattedMessage entry="merchantState.closingSoon" />
          </ClosingSoonChip>
        )}
        <StyledPromoChip
          promotions={promotions}
          topMerchantsFilterApplied={topMerchantsOnly}
        />
      </ImageContainer>
      <Details>
        <Header data-testid="merchant-card-header">
          <Name>{name}</Name>
          {loyaltyPlusProgram && loyaltyPlusProgram.maxStampCards > 0 && (
            <LoyaltyIcon />
          )}
        </Header>
        {!!tags?.length && (
          <Categories data-testid="merchant-card-categories">
            {tags.join(CATEGORY_SEPARATOR)}
          </Categories>
        )}
        <Address>{streetAddress}</Address>
        {showDistance && (
          <Distance data-testid="merchant-card-distance">
            {formattedDistanceString}
          </Distance>
        )}
      </Details>
    </LinkContainer>
  );
};

MerchantCard.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({
    streetAddress: PropTypes.string.isRequired,
  }).isRequired,
  imageUrl: PropTypes.string,
  menuPath: PropTypes.string.isRequired,
  isAvailableNow: PropTypes.bool,
  isOpenNow: PropTypes.bool,
  nextCloseTimestamp: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  loyaltyPlusProgram: PropTypes.shape({
    maxStampCards: PropTypes.number.isRequired,
  }),
  promotions: PropTypes.arrayOf(PromotionShape),
  topMerchantsOnly: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  nextOpenHours: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  formattedDistanceString: PropTypes.string,
  showDistance: PropTypes.bool,
  hasScheduledOrdering: PropTypes.bool,
  handleClick: PropTypes.func,
  pathParams: PropTypes.shape({
    city: PropTypes.string,
    cuisine: PropTypes.string,
  }),
  queryParams: PropTypes.string,
};

export default MerchantCard;
