import styled from 'styled-components';
import BaseSelectionBox, { CHECKBOX } from '@artemis/components/SelectionBox';

export const ButtonToggle = styled(BaseSelectionBox).attrs({
  type: CHECKBOX,
  tabindex: 0,
})`
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.rtColors.black800};
  color: ${({ theme }) => theme.rtColors.black800};
  padding: 8px 18px;
  border-radius: 100px;
  ${props => props.theme.typography.bodySmall};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  font-family: ${({ theme }) => theme.fontFamily};
  transition: background 0.25s ease-in, border 0.25s ease-in,
    color 0.25s ease-in;
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &:focus-within {
    background-color: ${({ theme }) => theme.rtColors.black100};
  }
  ${({ selected, theme }) =>
    selected &&
    `
      background-color: ${theme.rtColors.black800};
      border-color: ${theme.rtColors.black800};
      color: ${theme.palette.common.white};

      &:hover,
      &:focus-within {
        background-color: ${theme.rtColors.black700};
        border-color: ${theme.rtColors.black700};
      }
    `};
  svg {
    display: none;
  }
`;
