const mapGoogleResultToAddressDetails = addressComponents => {
  const addressComponentsKeyByType = addressComponents.reduce(
    (result, { long_name: longName, short_name: shortName, types }) => {
      const updatedResult = { ...result };

      types.forEach(type => {
        updatedResult[type] = { longName, shortName };
      });

      return updatedResult;
    },
    {},
  );

  const getAddressComponentByRank = (types, options = { short: false }) => {
    const matchedType = types.find(type => addressComponentsKeyByType[type]);
    if (!matchedType) return null;

    const { shortName, longName } = addressComponentsKeyByType[matchedType];

    return options.short ? shortName : longName;
  };

  return {
    city: getAddressComponentByRank([
      'locality',
      'postal_town',
      'administrative_area_level_3',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ]),
    country: getAddressComponentByRank(['country']),
    countryCode: getAddressComponentByRank(['country'], {
      short: true,
    }),
    province: getAddressComponentByRank(['administrative_area_level_1']),
    provinceCode: getAddressComponentByRank(['administrative_area_level_1'], {
      short: true,
    }),
    postalCode: getAddressComponentByRank(['postal_code']),
    subpremise: getAddressComponentByRank(['subpremise']),
  };
};

export default mapGoogleResultToAddressDetails;
