import {
  logEvent,
  EVENT_PARAM_TYPE_INTEGER,
  EVENT_PARAM_TYPE_STRING,
  track,
} from '@artemis/utils/analytics';

const EVENT_ACTION_RW_SEARCH = 'RW_SEARCH';
const EVENT_ACTION_RW_SEARCH_MERCHANT_CLICK = 'RW_SEARCH_MERCHANT_CLICK';
const EVENT_ACTION_RW_HOMESCREEN_IMPRESSION = 'RW_HOMESCREEN_IMPRESSION';
const EVENT_ACTION_RW_LANDING_PAGE_FILTER_CLICK =
  'RW_LANDING_PAGE_FILTER_CLICK';

export const logRWSearchEvent = ({ filters, numResults }) => {
  const { query, latitude, longitude } = filters;
  logEvent(EVENT_ACTION_RW_SEARCH, [
    {
      key: 'SEARCH_TERM',
      value: query || null,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'FILTER',
      value: JSON.stringify(filters),
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'LOCATION_PARAMETER',
      value: latitude && longitude ? `[${latitude}, ${longitude}]` : null,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'NUM_RESULTS',
      value: numResults,
      type: EVENT_PARAM_TYPE_INTEGER,
    },
  ]);
};

export const logRWSearchMerchantClickEvent = (
  index,
  merchantId,
  searchTerm,
) => {
  logEvent(EVENT_ACTION_RW_SEARCH_MERCHANT_CLICK, [
    {
      key: 'LIST_RANK',
      value: index,
      type: EVENT_PARAM_TYPE_INTEGER,
    },
    {
      key: 'MERCHANT_ID',
      value: merchantId,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'SEARCH_TERM',
      value: searchTerm,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ]);
};

export const logRWHomescreenImpression = impressionSource => {
  logEvent(EVENT_ACTION_RW_HOMESCREEN_IMPRESSION, [
    {
      key: 'IMPRESSION_SOURCE',
      value: impressionSource,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ]);
};

export const logFilterClick = ({ filterName, filterValue }) => {
  track(EVENT_ACTION_RW_LANDING_PAGE_FILTER_CLICK, {
    FILTER_NAME: filterName,
    FILTER_VALUE: filterValue,
  });
};
