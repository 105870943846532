import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';
import ShowMoreButton from '@artemis/components/MerchantLocationList/ShowMoreButton';
import { logRWSearchMerchantClickEvent } from './analytics';
import { EmptySearchState } from './EmptySearchState';
import MerchantCard from './MerchantCard';

const MoreButton = styled(ShowMoreButton)`
  margin: 0 0 32px;
`;

const MerchantListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  margin-bottom: 32px;
  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.isTablet`
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;
const EmptySearchStateContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 56px;
`;
export const MerchantList = ({
  merchants = [],
  className = null,
  hasNextPage = false,
  showMore = null,
  searchQuery = null,
  topMerchantsOnly = false,
  pathParams,
  queryParams,
}) => {
  const emptySearchStateText = useFormatMessage({
    entry: 'landing.emptySearchStateText',
  });

  if (!merchants || !merchants.length) {
    return (
      <EmptySearchStateContainer>
        <EmptySearchState message={emptySearchStateText} />
      </EmptySearchStateContainer>
    );
  }

  // handles analytic call when merchant card is clicked
  // href is still used for redirect
  const handleMerchantCardClick = (index, merchantId) => () => {
    logRWSearchMerchantClickEvent(index, merchantId, searchQuery);
  };
  return (
    <div className={className}>
      <MerchantListContainer>
        {merchants.map(({ id, ...merchantData }, index) => (
          <MerchantCard
            key={id}
            showDistance
            {...merchantData}
            topMerchantsOnly={topMerchantsOnly}
            handleClick={handleMerchantCardClick(index, id)}
            pathParams={pathParams}
            queryParams={queryParams}
          />
        ))}
      </MerchantListContainer>
      {hasNextPage && <MoreButton onClick={() => showMore(true)} />}
    </div>
  );
};

MerchantList.propTypes = {
  merchants: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  hasNextPage: PropTypes.bool,
  showMore: PropTypes.func,
  topMerchantsOnly: PropTypes.bool,
  searchQuery: PropTypes.string,
  pathParams: PropTypes.shape({
    city: PropTypes.string,
    cuisine: PropTypes.string,
    catering: PropTypes.bool,
  }),
  queryParams: PropTypes.string,
};
