import cookie from 'cookie';
import Cookies from '@artemis/utils/cookies';
import mapGoogleResultToAddressDetails from '@artemis/utils/mapGoogleResultToAddressDetails';
import {
  FILTER_CITY_PARAM,
  FILTER_CUISINE_PARAM,
  FILTER_DELIVERY_PARAM,
  FILTER_LATITUDE_PARAM,
  FILTER_LONGITUDE_PARAM,
  FILTER_OPEN_PARAM,
  FILTER_SEARCH_PARAM,
  FILTER_TOP_PARAM,
} from '@artemis/utils/query/constants';
import { queryCheck } from '@artemis/utils/query/useQueryCheck';
import {
  QUERY_CITIES,
  DELIVERY,
  PICKUP,
  GEOLOCATION_COOKIE,
  QUERY_CUISINES,
  CITY_MAP,
} from './constants';

export const getCity = addressComponents => {
  const location = mapGoogleResultToAddressDetails(addressComponents);
  if (
    location.city &&
    location.province &&
    CITY_MAP?.[`${location.city} ${location.province}`]
  ) {
    return CITY_MAP?.[`${location.city} ${location.province}`];
  }
  return '';
};

const getQuery = (search, cuisine) => {
  if (cuisine) {
    return { query: QUERY_CUISINES[cuisine].searchTerm, queryCuisine: cuisine };
  }
  if (search) {
    return { query: search };
  }
  return {};
};

const getLocation = (lat, lon, city, geolocation) => {
  if (lat && lon) {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lon);
    if (
      geolocation &&
      geolocation.lat === latitude &&
      geolocation.lon === longitude
    ) {
      return {
        latitude,
        longitude,
        locationQuery: geolocation.formattedAddress,
      };
    }
    return { latitude, longitude };
  }
  if (city) {
    return {
      latitude: QUERY_CITIES[city].lat,
      longitude: QUERY_CITIES[city].lon,
    };
  }
  if (geolocation) {
    return {
      latitude: geolocation.lat,
      longitude: geolocation.lon,
      locationQuery: geolocation.formattedAddress,
      city: geolocation.city,
      fromCookie: true,
    };
  }
  return {};
};

export const getPageFetchFilter = (query, req = null) => {
  const {
    [FILTER_LATITUDE_PARAM]: lat,
    [FILTER_LONGITUDE_PARAM]: lon,
    [FILTER_SEARCH_PARAM]: search,
    [FILTER_CITY_PARAM]: city,
    [FILTER_CUISINE_PARAM]: cuisine,
  } = query;
  const [open, delivery, top] = queryCheck(query, [
    FILTER_OPEN_PARAM,
    FILTER_DELIVERY_PARAM,
    FILTER_TOP_PARAM,
  ]);
  const cookies = cookie.parse(req?.headers?.cookie || '');
  const geolocation = cookies[GEOLOCATION_COOKIE]
    ? JSON.parse(cookies[GEOLOCATION_COOKIE])
    : null;
  return {
    ...getQuery(search, cuisine),
    ...getLocation(lat, lon, city, geolocation),
    ...(city && { city }),
    ...(open && { isAvailableNow: true }),
    ...(delivery
      ? { fulfillmentType: DELIVERY, hasDelivery: true }
      : { fulfillmentType: PICKUP }),
    ...(top && { isTopMerchant: true }),
  };
};

export const setGeolocationCookie = geolocation => {
  const value = {
    lat: geolocation.lat,
    lon: geolocation.lon,
    formattedAddress: geolocation.formattedAddress,
    city: getCity(geolocation.addressComponents),
  };
  Cookies.set(GEOLOCATION_COOKIE, JSON.stringify(value), {
    expires: 365,
    domain: process.env.RT_SHARED_COOKIE_DOMAIN,
    sameSite: 'Lax',
  });
};
