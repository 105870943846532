import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Loading from '@artemis/components/Loading';

const StyledLoading = styled(Loading)`
  border-radius: 100px;
  > div > div {
    top: 15px;
  }
`;

const Container = styled.div`
  background: ${props => props.theme.rtColors.black100};
  border: none;
  border-radius: 100px;
  display: flex;
  height: 48px;
  padding: 0;
  position: relative;
  width: 100%;
  &:focus {
    outline: none;
  }
  ${props => props.theme.isDesktop`
    width: 300px;
  `}
`;

const ActiveOption = styled.div`
  background: ${props => props.theme.rtColors.white};
  border: 3px solid ${props => props.theme.rtColors.black100};
  border-radius: 100px;
  box-sizing: border-box;
  height: 100%;
  left: 0px;
  position: absolute;
  transition: transform 0.2s ease;
  width: 50%;
  ${props => props.position === 1 && 'transform: translateX(100%);'}
`;

const ToggleOption = styled.button`
  ${props => props.theme.typography.body};
  align-items: center;
  background: none;
  border: 0;
  color: ${props => props.theme.rtColors.black800};
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0 16px;
  width: 50%;
  z-index: 1;
  > svg {
    color: ${props => props.theme.rtColors.black800} !important;
    /* Need to specify the size here to avoid flash of unstyled content */
    height: 1em;
    width: 1em;
  }
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.rtColors.black1000};
      > svg {
        color: ${theme.rtColors.black1000} !important;
      }
    `};
`;

const SingleOption = styled.button`
  ${props => props.theme.typography.body};
  align-items: center;
  background: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.rtColors.black200};
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  height: 48px;
  justify-content: center;
  padding: 0 36px;
  position: relative;
  > svg {
    /* Need to specify the size here to avoid flash of unstyled content */
    height: 1em;
    width: 1em;
  }
`;

// Supports one or two options
const Toggle = ({ active, isLoading, options, className, ...rest }) => {
  const numOptions = options.length;
  if (numOptions === 1) {
    return (
      <SingleOption onClick={options[0].onClick} className={className}>
        <StyledLoading isLoading={isLoading} />
        {options[0].label}
      </SingleOption>
    );
  }

  const position = options.findIndex(option => active === option.id);
  return (
    <Container className={className} {...rest}>
      <StyledLoading isLoading={isLoading} />
      <ActiveOption position={position} />
      {options.map(({ id, label, onClick, onClickActive }) => (
        <ToggleOption
          key={id}
          active={active === id}
          onClick={() => {
            if (active !== id) {
              onClick();
            } else if (onClickActive) {
              onClickActive();
            }
          }}
        >
          {label}
        </ToggleOption>
      ))}
    </Container>
  );
};

Toggle.propTypes = {
  /** The id of the active option */
  active: PropTypes.string,
  /** Loading status */
  isLoading: PropTypes.bool,
  /** An array of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.node,
      onClick: PropTypes.func,
      onClickActive: PropTypes.func,
    }),
  ),
  className: PropTypes.string,
};

export default Toggle;
