import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const Image = styled(ResponsiveImage)`
  margin-bottom: 8px;
  width: 100px;
`;

const EmptySearchMessage = styled.p`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.palette.grey[600]};
  ${props => props.theme.typography.h5};
`;

export const EmptySearchState = ({ message, className }) => (
  <Container className={className}>
    <Image id="landing.emptySearchState.img" />
    <EmptySearchMessage>{message}</EmptySearchMessage>
  </Container>
);

EmptySearchState.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
};
