// Cities are the kebab-case combination of the
// city name and its ISO 3166-2 subdivision code
export const QUERY_CITIES = {
  'toronto-on': {
    lat: 43.65,
    lon: -79.4,
    entry: 'landing.city.toronto',
  },
  'new-york-city-ny': {
    lat: 40.75,
    lon: -73.99,
    entry: 'landing.city.newYorkCity',
  },
  'chicago-il': {
    lat: 41.88,
    lon: -87.63,
    entry: 'landing.city.chicago',
  },
  'sydney-nsw': {
    lat: -33.87,
    lon: 151.21,
    entry: 'landing.city.sydney',
  },
  'vancouver-bc': {
    lat: 49.28,
    lon: -123.12,
    entry: 'landing.city.vancouver',
  },
};

// Cuisine keys must be kebab-case
export const QUERY_CUISINES = {
  pizza: {
    entry: 'landing.cuisine.pizza',
    searchTerm: 'pizza',
  },
  sushi: {
    entry: 'landing.cuisine.sushi',
    searchTerm: 'sushi',
  },
  taco: {
    entry: 'landing.cuisine.taco',
    searchTerm: 'taco',
  },
  sandwich: {
    entry: 'landing.cuisine.sandwich',
    searchTerm: 'sandwich',
  },
  italian: {
    entry: 'landing.cuisine.italian',
    searchTerm: 'italian',
  },
  mexican: {
    entry: 'landing.cuisine.mexican',
    searchTerm: 'mexican',
  },
};

export const CITY_MAP = {
  'New York New York': 'new-york-city-ny',
  'Toronto Ontario': 'toronto-on',
  'Vancouver British Columbia': 'vancouver-bc',
  'Sydney New South Wales': 'sydney-nsw',
  'Chicago Illinois': 'chicago-il',
};

export const GEOLOCATION_COOKIE = 'rw_geoloc';
export const QUERY_LIMIT = 36;

export const DELIVERY = 'delivery';
export const PICKUP = 'pickup';

export const LANDING_PAGE_STRINGS = [
  'brand',
  'downloadApp',
  'landing',
  'merchantState',
  'promo',
];
