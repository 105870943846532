import styled from 'styled-components';

const Chip = styled.span`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.rtColors.white};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  padding: 8px 10px;
  border-radius: 6px;
`;

export default Chip;
