import React, { useState, useMemo, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getCategories } from '@artemis/api/contentGateway';
import useContentGatewayClient from '@artemis/api/useContentGatewayClient';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { ButtonToggle } from '@artemis/components/ButtonToggle';
import HalfWidthSearchContainer from '@artemis/components/HalfWidthSearchContainer';
import Loading from '@artemis/components/Loading';
import ScrollableCategoryList from '@artemis/components/ScrollableCategoryList';
import Search from '@artemis/components/Search';
import Toggle from '@artemis/components/Toggle';
import LocationSearchField from '@artemis/containers/LocationSearchField';
import { DELIVERY, PICKUP } from './constants';
import { setGeolocationCookie, getCity } from './utils';
import { logFilterClick } from './analytics';

const Container = styled.div`
  overflow: hidden;
  padding-bottom: 22px;
  ${props => props.theme.isTablet`
    padding-bottom: 30px;
  `}
`;

const ShadowBox = styled.div`
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.17);
  padding-top: 32px;
`;

const Top = styled.div`
  box-sizing: border-box;
  padding-bottom: 16px;
  ${({ theme }) => theme.isTablet`
    margin: auto;
    max-width: 1236px;
    padding: 0 32px 20px;
  `}
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 0 16px;
  ${({ theme }) => theme.isTablet`
    flex-direction: row;
    margin-bottom: 40px;
    padding: 0;
  `}
`;

const Scrollable = styled.div`
  ${({ theme }) => theme.noScrollbars};
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0;
  overflow: auto;
  padding: 0 16px;
  white-space: nowrap;
  > * {
    white-space: nowrap;
  }
  ${({ theme }) => theme.isTablet`
    padding: 0;
  `}
`;

const StyledToggle = styled(Toggle)`
  display: inline-flex;
  width: unset;
  & > button {
    padding: 0 24px;
  }
`;

const FilterBarProps = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    query: PropTypes.string,
    queryCuisine: PropTypes.string,
    city: PropTypes.string,
    locationQuery: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    fulfillmentType: PropTypes.string,
    isAvailableNow: PropTypes.bool,
    hasGroupOrdering: PropTypes.bool,
    isTopMerchant: PropTypes.bool,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};
export const FilterBar = ({ className, onChange, initialValues }) => {
  const contentGatewayApiClient = useContentGatewayClient();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    query: initialValues.query || '',
    queryCuisine: initialValues.queryCuisine || '',
  });
  const [locationQuery, setLocationQuery] = useState(
    initialValues.locationQuery || '',
  );
  const [latLon, setLatLon] = useState({
    latitude: initialValues.latitude,
    longitude: initialValues.longitude,
  });
  const [fulfillmentType, setFulfillmentType] = useState(
    initialValues.fulfillmentType || PICKUP,
  );
  const [isAvailableNow, setIsAvailableNow] = useState(
    initialValues.isAvailableNow != null ? initialValues.isAvailableNow : false,
  );
  const [city, setCity] = useState(initialValues.city || '');
  const [hasGroupOrdering, setHasGroupOrdering] = useState(
    initialValues.hasGroupOrdering != null
      ? initialValues.hasGroupOrdering
      : false,
  );
  const [isTopMerchant, setIsTopMerchant] = useState(
    initialValues.isTopMerchant || false,
  );

  const [categories, setCategories] = useState(initialValues.categories || []);

  useEffect(() => {
    if (latLon.latitude != null && latLon.longitude != null) {
      const fetchCategories = async () => {
        try {
          const { data } = await getCategories(contentGatewayApiClient, {
            latLon,
            limit: 9,
          });
          setCategories(data.data);
        } catch (err) {
          // Fail quietly
          setCategories([]);
        }
      };
      fetchCategories();
    }
  }, [latLon]);

  const debouncedOnChange = useMemo(() => debounce(onChange, 250), []);
  useUpdateEffect(() => {
    debouncedOnChange({
      query: searchQuery.query,
      queryCuisine: searchQuery.queryCuisine,
      latitude: latLon.latitude,
      longitude: latLon.longitude,
      hasDelivery: fulfillmentType === DELIVERY,
      hasPickup: fulfillmentType === PICKUP,
      ...(city && { city }),
      isAvailableNow,
      hasGroupOrdering,
      isTopMerchant,
    });
    return () => {
      debouncedOnChange.cancel();
    };
  }, [searchQuery.query, searchQuery.queryCuisine]);

  useUpdateEffect(() => {
    onChange({
      query: searchQuery.query,
      queryCuisine: searchQuery.queryCuisine,
      latitude: latLon.latitude,
      longitude: latLon.longitude,
      hasDelivery: fulfillmentType === DELIVERY,
      hasPickup: fulfillmentType === PICKUP,
      ...(city && { city }),
      isAvailableNow,
      hasGroupOrdering,
      isTopMerchant,
    });
  }, [
    latLon.latitude,
    latLon.longitude,
    fulfillmentType,
    isAvailableNow,
    hasGroupOrdering,
    isTopMerchant,
  ]);

  const searchPlaceholder = useFormatMessage({
    entry: 'landing.searchPlaceholder',
  });
  const locationPlaceholder = useFormatMessage({
    entry: 'landing.locationPlaceholder',
  });
  const showCategoriesList = !!categories?.length;

  return (
    <Container className={className}>
      <ShadowBox>
        <Top>
          <SearchWrapper>
            <HalfWidthSearchContainer>
              <Search
                placeholder={searchPlaceholder}
                searchQuery={searchQuery.query}
                onChange={e =>
                  setSearchQuery({
                    query: e.target.value,
                    queryCuisine: '',
                  })
                }
                clearSearch={() =>
                  setSearchQuery({ query: '', queryCuisine: '' })
                }
              />
            </HalfWidthSearchContainer>
            <HalfWidthSearchContainer>
              <Loading isLoading={isLoading} />
              <LocationSearchField
                placeholder={locationPlaceholder}
                setLatLon={({ lat, lon }) =>
                  setLatLon({ latitude: lat, longitude: lon })
                }
                setIsLoading={setIsLoading}
                setSearchText={setLocationQuery}
                setAddressComponents={addressComponents =>
                  setCity(getCity(addressComponents))
                }
                setGeolocation={setGeolocationCookie}
                searchText={locationQuery}
              />
            </HalfWidthSearchContainer>
          </SearchWrapper>
          <Scrollable>
            <StyledToggle
              active={fulfillmentType}
              options={[
                {
                  id: PICKUP,
                  label: <FormattedMessage entry="global.pickupLabel" />,
                  onClick: () => {
                    logFilterClick({
                      filterName: 'FULFILLMENT_TYPE',
                      filterValue: PICKUP,
                    });
                    setFulfillmentType(PICKUP);
                  },
                },
                {
                  id: DELIVERY,
                  label: <FormattedMessage entry="global.deliveryLabel" />,
                  onClick: () => {
                    logFilterClick({
                      filterName: 'FULFILLMENT_TYPE',
                      filterValue: DELIVERY,
                    });
                    setFulfillmentType(DELIVERY);
                  },
                },
              ]}
            />
            <ButtonToggle
              onClick={e => {
                logFilterClick({
                  filterName: 'OPEN',
                  filterValue: e.target.checked ? '1' : '0',
                });

                setIsAvailableNow(e.target.checked);
              }}
              selected={isAvailableNow}
            >
              <FormattedMessage entry="global.openNow" />
            </ButtonToggle>
            <ButtonToggle
              onClick={e => {
                logFilterClick({
                  filterName: 'GROUP_ORDERS',
                  filterValue: e.target.checked ? '1' : '0',
                });
                setHasGroupOrdering(e.target.checked);
              }}
              selected={hasGroupOrdering}
            >
              <FormattedMessage entry="global.groupOrdersFilter" />
            </ButtonToggle>
            <ButtonToggle
              onClick={e => {
                logFilterClick({
                  filterName: 'TOP_MERCHANT',
                  filterValue: e.target.checked ? '1' : '0',
                });
                setIsTopMerchant(e.target.checked);
              }}
              selected={isTopMerchant}
            >
              <FormattedMessage entry="landing.topMerchantFilter" />
            </ButtonToggle>
          </Scrollable>
        </Top>
      </ShadowBox>
      {showCategoriesList && (
        <ScrollableCategoryList
          categories={categories}
          handleCategoryClick={category => {
            setSearchQuery({ query: category, queryCuisine: '' });
          }}
          showAll
        />
      )}
    </Container>
  );
};

FilterBar.propTypes = FilterBarProps;
