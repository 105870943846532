import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  margin: 40px auto 0;
  max-width: 1236px;
  padding: 0 16px;
  ${props => props.theme.isTablet`
    padding: 0 32px;
  `}
`;

const Heading = styled.h1`
  ${props => props.theme.typography.h5};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 12px;
  ${props => props.theme.isTablet`
    ${props.theme.typography.h2};
    font-weight: ${props.theme.typography.fontWeightBold};
  `}
`;

const Subheading = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.rtColors.black900};
  ${props => props.theme.isTablet`
    ${props.theme.typography.bodyLarge};
    font-weight: ${props.theme.typography.fontWeightLight};
  `}
`;

export const Header = ({ className, heading, subheading }) => (
  <Container className={className}>
    <Heading>{heading}</Heading>
    <Subheading>{subheading}</Subheading>
  </Container>
);

Header.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};
