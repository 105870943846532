import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import Loading from '@artemis/components/Loading';
import styled from 'styled-components';
import { MerchantList } from './MerchantList';

export const SearchResultsWrapper = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1236px;
  padding: 0 16px;
  position: relative;
  ${props => props.theme.isTablet`
    padding: 0 32px;
  `}
`;

export const SectionHeading = styled.h2`
  ${props => props.theme.typography.h5};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  margin-bottom: 16px;
  ${props => props.theme.isTablet`
    margin-bottom: 32px;
  `}
`;

export const SearchResults = ({
  isLoading,
  merchants,
  showMore,
  hasNextPage,
  searchQuery,
  topMerchantsOnly = false,
  pathParams,
  queryParams,
}) => (
  <SearchResultsWrapper>
    <SectionHeading>
      {searchQuery ? (
        <FormattedMessage
          entry="landing.merchantListHeadingWithQuery"
          values={{ query: searchQuery }}
        />
      ) : (
        <FormattedMessage entry="landing.merchantListHeading" />
      )}
    </SectionHeading>
    <Loading isLoading={isLoading} data-testid="loading-spinner" />
    <MerchantList
      merchants={merchants}
      showMore={showMore}
      hasNextPage={hasNextPage}
      searchQuery={searchQuery}
      topMerchantsOnly={topMerchantsOnly}
      pathParams={pathParams}
      queryParams={queryParams}
    />
  </SearchResultsWrapper>
);

SearchResults.propTypes = {
  isLoading: PropTypes.bool,
  showMore: PropTypes.func,
  hasNextPage: PropTypes.bool,
  merchants: PropTypes.array,
  searchQuery: PropTypes.string,
  topMerchantsOnly: PropTypes.bool,
  pathParams: PropTypes.shape({
    city: PropTypes.string,
    cuisine: PropTypes.string,
    catering: PropTypes.bool,
  }),
  queryParams: PropTypes.string,
};
