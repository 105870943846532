import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Metadata from '@artemis/components/Metadata';
import {
  useImageUrlAlt,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { useDownloadAppEnabled } from '@artemis/utils/downloadApp';

const LandingPageMetadataProps = {
  isDownloadAppModalOpen: PropTypes.bool,
};

export const LandingPageMetadata = ({ isDownloadAppModalOpen }) => {
  const router = useRouter();
  const isDownloadAppEnabled = useDownloadAppEnabled();
  const metadataTitle = useFormatMessage({ entry: 'landing.metadataTitle' });
  const opengraphTitle = useFormatMessage({ entry: 'landing.opengraphTitle' });
  const metadataDescription = useFormatMessage({
    entry: 'landing.opengraphDescription',
  });
  const image = useImageUrlAlt('landing.opengraphImage.img');
  // Dynamically includes canonical link for routes that render this page
  const canonical = queryString.parseUrl(
    `https://ritual.co${router.asPath}`,
  ).url;
  const appleItunesApp =
    isDownloadAppEnabled && !isDownloadAppModalOpen
      ? `app-id=${process.env.RT_APPLE_APP_STORE_ID}`
      : undefined;

  return (
    <Metadata
      title={metadataTitle}
      description={metadataDescription}
      openGraph={{
        title: opengraphTitle,
        description: metadataDescription,
        url: canonical,
        image,
      }}
      canonical={canonical}
      appleItunesApp={appleItunesApp}
    />
  );
};

LandingPageMetadata.propTypes = LandingPageMetadataProps;
