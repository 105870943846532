import React from 'react';
import styled from 'styled-components';
import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';
import { AppStore, GooglePlay } from '@artemis/components/Images';
import ResponsiveImage from '@artemis/components/ResponsiveImage';
import { QUERY_CITIES, QUERY_CUISINES } from './constants';

const FooterContainer = styled.footer`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1236px;
  padding: 0px 16px 24px;
  ${({ theme }) => theme.isTablet`
    padding: 0px 32px 24px;
  `}
`;

const LineContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.rtColors.black200};
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  max-width: 1236px;
  padding-top: 22px;
  ${({ theme }) => theme.isTablet`
    grid-column-gap: 10px;
    grid-row-gap: 50px;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
  `}
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 6px;
  ${({ theme }) => theme.isTablet`
    grid-column: span 2;
  `}
`;

const SocialsContainer = styled.div`
  margin-right: 10%;
  margin-top: 48px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${({ theme }) => theme.isTablet`
    grid-column: span 3;
    margin-top: 0;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 16px;
`;

const Title = styled.p`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.rtColors.black};
  margin-bottom: 6px;
`;

const TitleLink = styled.a.attrs({ target: '_self' })`
  font-weight: ${props => props.theme.typography.fontWeightBold};
  color: ${props => props.theme.palette.common.black};
  margin-bottom: 6px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialsTitle = styled.p`
  ${props => props.theme.typography.h5};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const Link = styled.a.attrs({ target: '_self' })`
  color: ${({ theme }) => theme.rtColors.black500};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const IconLink = styled.a.attrs({ target: '_self' })`
  margin-right: 15px;
`;

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const footerSections = [
  {
    title: 'landing.company',
    links: [
      { label: 'landing.about', link: 'landing.aboutLink' },
      { label: 'landing.careers', link: 'landing.careersLink' },
      { label: 'landing.termsOfService', link: 'landing.termsOfServiceLink' },
      { label: 'landing.privacyPolicy', link: 'landing.privacyPolicyLink' },
      { label: 'landing.accessibility', link: 'landing.accessibilityLink' },
    ],
  },
  {
    title: 'landing.forCustomers',
    links: [
      {
        label: 'landing.saveOnFirstOrder',
        link: 'landing.saveOnFirstOrderLink',
      },
      { label: 'landing.addRestaurant', link: 'landing.addYourRestaurantLink' },
      { label: 'landing.ritualForTeams', link: 'landing.ritualForTeamsLink' },
      {
        label: 'landing.ritualForBuildings',
        link: 'landing.ritualForBuildingsLink',
      },
      { label: 'landing.downloadRitualApp', link: 'landing.downloadAppLink' },
    ],
  },
  {
    title: 'landing.resources',
    links: [
      { label: 'landing.successStories', link: 'landing.successStoriesLink' },
      { label: 'landing.blog', link: 'landing.blogLink' },
      { label: 'landing.faq', link: 'landing.faqLink' },
      { label: 'landing.helpCentre', link: 'landing.helpCentreLink' },
    ],
  },
];

const generateFoodLinkContainer = (
  city,
  titleEntry,
  linkEntry,
  pathParams = '',
) => (
  <LinkContainer key={city}>
    <TitleLink href={`/order${pathParams}/city/${city}`}>
      <FormattedMessage
        entry={titleEntry}
        values={{ city: <FormattedMessage entry={QUERY_CITIES[city].entry} /> }}
      />
    </TitleLink>
    <LinkList>
      {Object.keys(QUERY_CUISINES).map(cuisine => (
        <li key={cuisine + city + pathParams}>
          <Link href={`/order${pathParams}/city/${city}/cuisine/${cuisine}`}>
            <FormattedMessage
              entry={linkEntry}
              values={{
                cuisine: (
                  <FormattedMessage entry={QUERY_CUISINES[cuisine].entry} />
                ),
                city: <FormattedMessage entry={QUERY_CITIES[city].entry} />,
              }}
            />
          </Link>
        </li>
      ))}
    </LinkList>
  </LinkContainer>
);

const generateSectionContainer = section => (
  <LinkContainer key={section.title}>
    <Title>
      <FormattedMessage entry={section.title} />
    </Title>
    <LinkList>
      {section.links.map(linkItem => (
        <li key={linkItem.label}>
          <Link href={useFormatMessage({ entry: linkItem.link })}>
            <FormattedMessage entry={linkItem.label} />
          </Link>
        </li>
      ))}
    </LinkList>
  </LinkContainer>
);

export const Footer = () => (
  <FooterContainer>
    <LineContainer>
      {Object.keys(QUERY_CITIES).map(city =>
        generateFoodLinkContainer(
          city,
          QUERY_CITIES[city].entry,
          'landing.cuisineInCity',
        ),
      )}
      {Object.keys(QUERY_CITIES).map(city =>
        generateFoodLinkContainer(
          city,
          'landing.cateringInCity',
          'landing.cuisineCateringInCity',
          '/catering',
        ),
      )}
      {footerSections.map(generateSectionContainer)}
      <SocialsContainer>
        <SocialsTitle>
          <FormattedMessage entry="landing.slogan" />
        </SocialsTitle>
        <ImageContainer>
          <IconLink
            href={useFormatMessage({ entry: 'landing.googlePlayLink' })}
          >
            <GooglePlay />
          </IconLink>
          <IconLink href={useFormatMessage({ entry: 'landing.appStoreLink' })}>
            <AppStore />
          </IconLink>
        </ImageContainer>
        <ImageContainer>
          <IconLink href={useFormatMessage({ entry: 'landing.facebookLink' })}>
            <ResponsiveImage id="landing.facebookIcon.img" />
          </IconLink>
          <IconLink href={useFormatMessage({ entry: 'landing.linkedinLink' })}>
            <ResponsiveImage id="landing.linkedinIcon.img" />
          </IconLink>
          <IconLink href={useFormatMessage({ entry: 'landing.twitterLink' })}>
            <ResponsiveImage id="landing.twitterIcon.img" />
          </IconLink>
          <IconLink href={useFormatMessage({ entry: 'landing.instagramLink' })}>
            <ResponsiveImage id="landing.instagramIcon.img" />
          </IconLink>
        </ImageContainer>
      </SocialsContainer>
    </LineContainer>
  </FooterContainer>
);
